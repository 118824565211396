@import 'tale/variables';
@import 'tale/base';
@import 'tale/code';
@import 'tale/post';
@import 'tale/syntax';
@import 'tale/layout';
@import 'tale/pagination';
@import 'tale/catalogue';

/* force a rebuild */
.foobarbaz { color: red; }
